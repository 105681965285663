import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Top from './JS/Top';
import MatchKoba from './JS/MatchKoba';
import SmartMIrai from './JS/SmartMIrai';
import Construction from './JS/Construction';
import Shanette from './JS/Shanette';
import SoundProof from './JS/SoundProof';
import AntiSnow from './JS/AntiSnow';
import SnowRoof from './JS/SnowRoof';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Top />}></Route>
        <Route path='/MatchKoba' element={<MatchKoba />}></Route>
        <Route path='/SmartMIrai' element={<SmartMIrai />}></Route>
        <Route path='/Construction' element={<Construction />}></Route>
        <Route path='/Shanette' element={<Shanette />}></Route>
        <Route path='/SoundProof' element={<SoundProof />}></Route>
        <Route path='/AntiSnow' element={<AntiSnow />}></Route>
        <Route path='/SnowRoof' element={<SnowRoof />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
