import '../CSS/Style.css';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import title from '../Image/title.png';
import event from '../Image/event.png';
import ShanetteImg from '../Image/ShanetteImg.png';
import EnergyImg1 from '../Image/EnergyImg1.png';
import EnergyImg2 from '../Image/EnergyImg2.png';
import EnergyImg3 from '../Image/EnergyImg3.png';

const SCRIPT_URL = 'https://sdk.form.run/js/v2/embed.js';
const FORMRUN_FORM = '@nemy-56qmmZ1IrN1nz1Po1qL7';

export default function Shanette () {

    useEffect(() => {
        let timeoutId = setTimeout(() => {
            const head = document.getElementsByTagName('head')[0]
            const scriptElement = document.createElement('script')
            scriptElement.src = SCRIPT_URL
        
            head.appendChild(scriptElement)
            return () => {
                head.removeChild(scriptElement)
            }
        }, 2000)
     
        return () => {
            clearTimeout(timeoutId)
        }
    
    }, [])

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        // Target node to observe
        const targetNode = document.getElementById('wait_loading')
    
        // Options for the observer - observe 'childList' mutation and the subtree under the targetNode
        const config = { childList: true, subtree: true }
    
        // Creation of the Mutation Observer
        const observer = new MutationObserver((mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                    for (let node of mutation.addedNodes) {
                        if (node.id === 'iframe' || node.tagName === 'IFRAME') {
                            // Stop the observer
                            observer.disconnect()
                            // Execute necessary code when node is added
                            setLoading(false)
                        }
                    }
                }
            }
        })
    
        // Start observing
        observer.observe(targetNode, config)
    
        // Clean up by disconnecting the observer when the component unmounts
        return () => observer.disconnect()
    }, [])

    const VRClick1 = () => {
        const url = 'https://nemy.co.jp/hvac/2024/VRtour/';
        window.open(url, '_blank')
    }

    return(
        <div className='PageBase'>
            <div className='TopBack'>
                <Link to='/'><img src={title} className='TitleImg' alt=''/></Link>
                <img src={event} className='EventImg' alt=''/>
                <div className='EventTextArea'>
                    <p className='EventText'>HVACブース来場、<br/>ありがとうございます！</p>
                </div>
                <p className='EventVR' onClick={() => {VRClick1()}}>展示会ブースVRtour公開中</p>
            </div>
            <div className='AreaBack LineUpLabel'>
                <div className='LineUpTopArea'>
                    <p className='LineUpTopTitle1'>　省エネ商材シャネット</p>
                    <p className='LineUpTopTitle2'>
                        1歩先の省エネ対策！<br/>
                        空調機の運転効率UP！
                    </p>
                </div>
                <img src={ShanetteImg} className='LineUpTopImg' alt=''/>
            </div>
            <div className='AreaBack GroupBack'>
                <div className='PageSpace'></div>
                <p className='ShanetteTitle'>省エネ商材シャネットとは？</p>
                <div className='BorderB MKline'></div>
                <div className='ShanetteY'>
                    <div className='ShanetteX'>
                        <img src={EnergyImg1} className='ShanetteImg' alt=''/>
                    </div>
                    <div className='ShanetteX'>
                        <p className='ShanetteQTitle'>FEATURES1</p>
                        <p className='ShanetteQTextB'>温度上昇を抑え<br/>突然の運転停止を防ぐ！</p>
                        <p className='ShanetteQTextS'>
                            真夏の気温は年々上がる一方で、空調機が突然止まってしま<br/>
                            う「高圧カット」トラブルにお困りですか？スーパーやコン<br/>
                            ビニ、食品倉庫などの空調きや冷凍機が止まることで、二次<br/>
                            的被害のリスクが生じます。空調機トラブルあ直接売上に直<br/>
                            結してしまうリスクを少しでも減らすために、シャネットが<br/>
                            役立ちます。
                        </p>
                    </div>
                </div>
                <div className='ShanetteY'>
                    <div className='ShanetteX'>
                        <p className='ShanetteQTitle SQTST'>FEATURES2</p>
                        <p className='ShanetteQTextB SQTST'>消費電力を抑えて<br/>コスト削減を実現！</p>
                        <p className='ShanetteQTextS SQTST'>
                            シャネットの効果で空調機の運転効率を保つため、月々<br/>
                            の電気代が抑えられます。
                        </p>
                    </div>
                    <div className='ShanetteX'>
                        <img src={EnergyImg2} className='ShanetteImg' alt=''/>
                    </div>
                </div>
                <div className='ShanetteY'>
                    <div className='ShanetteX'>
                        <img src={EnergyImg3} className='ShanetteImg' alt=''/>
                    </div>
                    <div className='ShanetteX'>
                        <p className='ShanetteQTitle'>FEATURES3</p>
                        <p className='ShanetteQTextB'>シャネットなら<br/>導入後ランニング費用０円！</p>
                        <p className='ShanetteQTextS'>
                            シャネットは、特許出願中の特殊なネットを採用しており環<br/>
                            境省ETV事業にて実証された製品です。シャネットを空調機<br/>
                            に取り付けることにより、太陽の直射日光から守り、周辺<br/>
                            温度を約3~15℃下げる効果があります。また空調基本来の<br/>
                            運転効率を保つことができ、長持ちします。
                        </p>
                    </div>
                </div>
                <div className='PageSpace'></div>
            </div>
            <div className='AreaBack FAQBack'>
                <div className='FAQAreaL'>
                    <p className="FAQTitle"><br/>FAQ</p>
                    <p className='FAQTitleSet'>よくあるご質問</p>
                    <div className='BorderB FAQline'></div>
                </div>
                <div className='FAQAreaR CABottom'>
                    <div className='FAQBanner'>
                        <p className="QuestionText"><br/><span className="Qtext">Q1.</span>年間どれくらい電力使用量が下がるのか？</p>
                        <p className="AnswerText">削減できるのは空調設備の電力使用量にはなりますが、<br/>夏場・冬場の削減量を年間で考えた場合、<br/>大体平均15～20％程度削減は出来るものです。</p>
                    </div>
                    <div className='FAQBanner'>
                        <p className="QuestionText"><br/><span className="Qtext">Q2.</span>冬も効果があるのか？</p>
                        <p className="AnswerText">冬の場合は寒気から室外機を保護することで<br/>夏場ほどではないのですが、１５％前後の効果が見込めます。</p>
                    </div>
                    <div className='FAQBanner'>
                        <p className="QuestionText"><br/><span className="Qtext">Q3.</span>回収期間はどれくらい掛かるのか？</p>
                        <p className="AnswerText">設置現場の電気の使い方、設備の規模によって異なりますが、<br/>約3年から5年で回収できる現場が多いです。</p>
                    </div>
                    <div className='FAQBanner'>
                        <p className="QuestionText"><br/><span className="Qtext">Q4.</span>風は大丈夫なのか？</p>
                        <p className="AnswerText">ネットの形状で風を通すことで抵抗を受けない特性があります。<br/>実績ベースで基準風速34m/sまで設置可能です。突風などが多い位置条件の場合、<br/>最初から補強材を追加して設置することも可能です。</p>
                    </div>
                    <div className='FAQBanner'>
                        <p className="QuestionText"><br/><span className="Qtext">Q5.</span>ネットの耐久性は？</p>
                        <p className="AnswerText">ネットの材質がポリエチレン（樹脂）なので鋭利なもので<br/>切らない限り自然上で破れることはないです。<br/>耐久年数で言うと10年程度となります。</p>
                    </div>
                </div>
            </div>
            <div>
                {loading ? <div className="wait_loading_message">
                    <div className="loading">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <p>フォームの準備中です。しばらくお待ちください。</p>
                </div> : ''}
                <div id="wait_loading">
                    <div className="formrun-embed" data-formrun-form={FORMRUN_FORM} data-formrun-redirect="true" />
                </div>
            </div>
            <div className='AreaBottom'>
                <p className='FooterText'>Copyrigh © ネミー株式会社| IRサイト All Rights Reseved.</p>
            </div>
        </div>
    )
}