import '../CSS/Style.css';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import title from '../Image/title.png';
import event from '../Image/event.png';
import MAINImg from '../Image/MAIN.svg';
import FEATURES1 from '../Image/FEATURES_01.svg';
import SIZEImg from '../Image/SIZE.svg';
import CASE_01 from '../Image/CASE_01.svg';
import CASE_02 from '../Image/CASE_02.svg';

const SCRIPT_URL = 'https://sdk.form.run/js/v2/embed.js';
const FORMRUN_FORM = '@nemy-56qmmZ1IrN1nz1Po1qL7';

export default function SnowRoof () {

    useEffect(() => {
        let timeoutId = setTimeout(() => {
            const head = document.getElementsByTagName('head')[0]
            const scriptElement = document.createElement('script')
            scriptElement.src = SCRIPT_URL
        
            head.appendChild(scriptElement)
            return () => {
                head.removeChild(scriptElement)
            }
        }, 2000)
     
        return () => {
            clearTimeout(timeoutId)
        }
    
    }, [])

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        // Target node to observe
        const targetNode = document.getElementById('wait_loading')
    
        // Options for the observer - observe 'childList' mutation and the subtree under the targetNode
        const config = { childList: true, subtree: true }
    
        // Creation of the Mutation Observer
        const observer = new MutationObserver((mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                    for (let node of mutation.addedNodes) {
                        if (node.id === 'iframe' || node.tagName === 'IFRAME') {
                            // Stop the observer
                            observer.disconnect()
                            // Execute necessary code when node is added
                            setLoading(false)
                        }
                    }
                }
            }
        })
    
        // Start observing
        observer.observe(targetNode, config)
    
        // Clean up by disconnecting the observer when the component unmounts
        return () => observer.disconnect()
    }, [])

    const VRClick1 = () => {
        const url = 'https://nemy.co.jp/hvac/2024/VRtour/';
        window.open(url, '_blank')
    }

    return(
        <div className='PageBase'>
            <div className='TopBack'>
                <Link to='/'><img src={title} className='TitleImg' alt=''/></Link>
                <img src={event} className='EventImg' alt=''/>
                <div className='EventTextArea'>
                    <p className='EventText'>HVACブース来場、<br/>ありがとうございます！</p>
                </div>
                <p className='EventVR' onClick={() => {VRClick1()}}>展示会ブースVRtour公開中</p>
            </div>
            <div className='AreaBack LineUpLabel'>
                <div className='LineUpTopArea'>
                    <p className='LineUpTopTitle1'>　防雪アイテム</p>
                    <p className='LineUpTopTitle2'>
                        雪と太陽光から、<br/>
                        室外機を守る「防雪屋根」
                    </p>
                </div>
                <img src={MAINImg} className='LineUpTopImg' alt=''/>
            </div>
            <div className='AreaBack GroupBack'>
                <div className='PageSpace'></div>
                <p className='ShanetteTitle'>防雪アイテムとは？</p>
                <div className='BorderB MKline'></div>
                <div className='ShanetteY'>
                    <div className='ShanetteX'>
                        <img src={FEATURES1} className='ShanetteImg' alt=''/>
                    </div>
                    <div className='ShanetteX'>
                        <h2><span className='MidashiNo'>01</span><span className='MidashiTitle'>FEATURES</span></h2>
                        <p className='ShanetteQTextB Coler2P'>温度上昇を抑え<br/>突然の運転停止を防ぐ！</p>
                        <p className='ShanetteQTextS'>
                            壁と天板を密着させ、 背面側のフィンに雪が<br/>
                            目詰まりするのを防ぎます。
                        </p>
                    </div>
                </div>
                <div className='ShanetteY'>
                    <div className='ShanetteX'>
                    </div>
                    <div className='ShanetteX'>
                        <h2><span className='MidashiNo'>02</span><span className='MidashiTitle'>FEATURES</span></h2>
                        <p className='ShanetteQTextB Coler2P'>様々な設置状況に対応可能</p>
                        <p className='ShanetteQTextS'>
                            天板長さは調整可能のため、壁との距離に<br/>
                            合わせて設置が可能です。（最大壁面距離A[mm]=420-D/2）
                        </p>
                    </div>
                </div>
                <div className='ShanetteY'>
                    <div className='ShanetteX'>
                    </div>
                    <div className='ShanetteX'>
                        <h2><span className='MidashiNo'>03</span><span className='MidashiTitle'>FEATURES</span></h2>
                        <p className='ShanetteQTextB Coler2P'>直射日光から守る</p>
                        <p className='ShanetteQTextS'>
                            直射日光による室外機の温度上昇を低減させ、<br/>
                            運転効率の低下を抑制します。
                        </p>
                    </div>
                </div>
                <div className='PageSpace'></div>
                <div className='BorderB MKline'></div>
                <div className='PageSpace'></div>
                <img src={SIZEImg} className='SIZEImg' alt=''/>
                <div className='PageSpace'></div>
                <div className='BorderB MKline'></div>
                <div className='PageSpace'></div>
                <div className='ShanetteY'>
                    <div className='ShanetteX'>
                        <img src={CASE_01} className='ShanetteImg' alt=''/>
                    </div>
                    <div className='ShanetteX'>
                        <p className='ShanetteQTitle'>CASE 1</p>
                        <p className='ShanetteQTextB'>フードの取り付けスペースがない現場</p>
                        <p className='ShanetteQTextS'>
                            壁までの距離が近く、フードが取り付けできない。
                        </p>
                    </div>
                </div>
                <div className='ShanetteY'>
                    <div className='ShanetteX'>
                        <p className='ShanetteQTitle'>CASE 2</p>
                        <p className='ShanetteQTextB'>フードの取り付けスペースがない現場</p>
                        <p className='ShanetteQTextS'>
                            雪がたまり、目詰まりを引き起こしてしまう。
                        </p>
                    </div>
                    <div className='ShanetteX'>
                        <img src={CASE_02} className='ShanetteImg' alt=''/>
                    </div>
                </div>
                <div className='PageSpace'></div>
            </div>
            <div>
                {loading ? <div className="wait_loading_message">
                    <div className="loading">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <p>フォームの準備中です。しばらくお待ちください。</p>
                </div> : ''}
                <div id="wait_loading">
                    <div className="formrun-embed" data-formrun-form={FORMRUN_FORM} data-formrun-redirect="true" />
                </div>
            </div>
            <div className='AreaBottom'>
                <p className='FooterText'>Copyrigh © ネミー株式会社| IRサイト All Rights Reseved.</p>
            </div>
        </div>
    )
}