import '../CSS/Style.css';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import title from '../Image/title.png';
import event from '../Image/event.png';
import SoundproofImg from '../Image/Soundproof.png';
import SoundImg1 from '../Image/SoundproofImg1.jpg';
import SoundImg2 from '../Image/SoundproofImg2.png';
import SoundImg3 from '../Image/SoundproofImg3.jpeg';

const SCRIPT_URL = 'https://sdk.form.run/js/v2/embed.js';
const FORMRUN_FORM = '@nemy-56qmmZ1IrN1nz1Po1qL7';

export default function SoundProof () {

    useEffect(() => {
        let timeoutId = setTimeout(() => {
            const head = document.getElementsByTagName('head')[0]
            const scriptElement = document.createElement('script')
            scriptElement.src = SCRIPT_URL
        
            head.appendChild(scriptElement)
            return () => {
                head.removeChild(scriptElement)
            }
        }, 2000)
     
        return () => {
            clearTimeout(timeoutId)
        }
    
    }, [])

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        // Target node to observe
        const targetNode = document.getElementById('wait_loading')
    
        // Options for the observer - observe 'childList' mutation and the subtree under the targetNode
        const config = { childList: true, subtree: true }
    
        // Creation of the Mutation Observer
        const observer = new MutationObserver((mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                    for (let node of mutation.addedNodes) {
                        if (node.id === 'iframe' || node.tagName === 'IFRAME') {
                            // Stop the observer
                            observer.disconnect()
                            // Execute necessary code when node is added
                            setLoading(false)
                        }
                    }
                }
            }
        })
    
        // Start observing
        observer.observe(targetNode, config)
    
        // Clean up by disconnecting the observer when the component unmounts
        return () => observer.disconnect()
    }, [])

    const VRClick1 = () => {
        const url = 'https://nemy.co.jp/hvac/2024/VRtour/';
        window.open(url, '_blank')
    }

    return(
        <div className='PageBase'>
            <div className='TopBack'>
                <Link to='/'><img src={title} className='TitleImg' alt=''/></Link>
                <img src={event} className='EventImg' alt=''/>
                <div className='EventTextArea'>
                    <p className='EventText'>HVACブース来場、<br/>ありがとうございます！</p>
                </div>
                <p className='EventVR' onClick={() => {VRClick1()}}>展示会ブースVRtour公開中</p>
            </div>
            <div className='AreaBack LineUpLabel'>
                <div className='LineUpTopArea'>
                    <p className='LineUpTopTitle1'>　防音</p>
                    <p className='LineUpTopTitle2'>
                        空調室外機、換気装置、<br/>
                        発電機、冷凍機などの<br/>
                        騒音対策に！
                    </p>
                </div>
                <img src={SoundproofImg} className='LineUpTopImg' alt=''/>
            </div>
            <div className='AreaBack GroupBack'>
                <div className='PageSpace'></div>
                <p className='ShanetteTitle'>防音とは？</p>
                <div className='BorderB MKline'></div>
                <div className='ShanetteY'>
                    <div className='ShanetteX'>
                        <img src={SoundImg1} className='ShanetteImg' alt=''/>
                    </div>
                    <div className='ShanetteX'>
                        <p className='ShanetteQTitle'>PRODUCT1</p>
                        <p className='ShanetteQTextB'>遮音パネル</p>
                        <p className='ShanetteQTextS'>
                            空調室外機、換気装置など屋外の騒音対策に！<br/>
                            外観も美しい「遮音パネル」<br/>
                            気になる機械の作動音をシャットアウト！
                        </p>
                    </div>
                </div>
                <div className='ShanetteY'>
                    <div className='ShanetteX'>
                        <p className='ShanetteQTitle SQTST'>PRODUCT2</p>
                        <p className='ShanetteQTextB SQTST'>防音ボックス</p>
                        <p className='ShanetteQTextS SQTST'>
                            四方八方で騒音をカット！<br/>
                            配管やダクトなどの貫通部は確保します！<br/>
                            外観が良く、丈夫で機密性に優れた組み立て式！
                        </p>
                    </div>
                    <div className='ShanetteX'>
                        <img src={SoundImg2} className='ShanetteImgT' alt=''/>
                    </div>
                </div>
                <div className='ShanetteY'>
                    <div className='ShanetteX'>
                        <img src={SoundImg3} className='ShanetteImgT SQTST' alt=''/>
                    </div>
                    <div className='ShanetteX'>
                        <p className='ShanetteQTitle'>PRODUCT3</p>
                        <p className='ShanetteQTextB'>サイレンサー</p>
                        <p className='ShanetteQTextS'>
                            質の高い消音技術を惜しみなく導入。<br/>
                            熟練の消音技術の結晶！<br/>
                            ものづくりができる問題解決型カンパニーを目指します！
                        </p>
                    </div>
                </div>
                <div className='PageSpace'></div>
            </div>
            <div className='AreaBack FAQBack'>
                <div className='FAQAreaL'>
                    <p className="FAQTitle"><br/>FAQ</p>
                    <p className='FAQTitleSet'>よくあるご質問</p>
                    <div className='BorderB FAQline'></div>
                </div>
                <div className='FAQAreaR CABottom'>
                    <div className='FAQBanner'>
                        <p className="QuestionText"><br/><span className="Qtext">Q1.</span>遮音パネルの規格を教えてください</p>
                        <p className="AnswerText">500・1000・1200・1500・2000の5種類が通常規格になります。</p>
                    </div>
                    <div className='FAQBanner'>
                        <p className="QuestionText"><br/><span className="Qtext">Q2.</span>材料のみの手配は可能ですか？</p>
                        <p className="AnswerText">可能です！ご依頼をいただきましたら工事込みか<br/>材料のみなのかヒアリングをさせてくださいませ。</p>
                    </div>
                    <div className='FAQBanner'>
                        <p className="QuestionText"><br/><span className="Qtext">Q3.</span>防音壁は、狭い場所でも設置ができますか？</p>
                        <p className="AnswerText">最低、機器と防音壁までの離隔が700㎜以上、<br/>手前側の作業スペースが700mm以上あれば設置可能です。</p>
                    </div>
                    <div className='FAQBanner'>
                        <p className="QuestionText"><br/><span className="Qtext">Q4.</span>防音壁用の推奨基礎を教えてください</p>
                        <p className="AnswerText">基本はコンクリート基礎になります<br/>折板屋根上・防水処理されている屋上等の場合は<br/>鉄骨（H鋼材等）を流しての基礎も大丈夫です。</p>
                    </div>
                    <div className='FAQBanner'>
                        <p className="QuestionText"><br/><span className="Qtext">Q5.</span>遮音パネルの最大高さを教えてください</p>
                        <p className="AnswerText">3.5m（7段）迄です。4mを超えて設置すると建築物の扱いになります。<br/>遮音パネルは高さが高いほうが距離が稼げるので効果が大きくなります。</p>
                    </div>
                    <div className='FAQBanner'>
                        <p className="QuestionText"><br/><span className="Qtext">Q5.</span>遮音パネルの取り付け方を教えてください</p>
                        <p className="AnswerText">鋼材柱の外側からパネルをはめ込む形で取付いたします。<br/>他社はH鋼間の溝にパネルを落とし込む工法が多いが、<br/>ネミーでは鋼材にビス止めするため、着脱可能な仕様です。</p>
                    </div>
                </div>
            </div>
            <div>
                {loading ? <div className="wait_loading_message">
                    <div className="loading">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <p>フォームの準備中です。しばらくお待ちください。</p>
                </div> : ''}
                <div id="wait_loading">
                    <div className="formrun-embed" data-formrun-form={FORMRUN_FORM} data-formrun-redirect="true" />
                </div>
            </div>
            <div className='AreaBottom'>
                <p className='FooterText'>Copyrigh © ネミー株式会社| IRサイト All Rights Reseved.</p>
            </div>
        </div>
    )
}