import '../CSS/Style.css';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import title from '../Image/title.png';
import event from '../Image/event.png';
import SmartMiraiImg from '../Image/SmartMirai.png';
import AboutImg from '../Image/img-about.png';
import AboutImg1 from '../Image/img-about-01.jpg';
import AboutImg2 from '../Image/img-about-02.jpg';
import ServiceImg1 from '../Image/img-service-01.jpg';
import ServiceImg2 from '../Image/img-service-02.jpg';
import ServiceImg3 from '../Image/img-service-03.jpg';
import ServiceImg4 from '../Image/img-service-04.jpg';
import ServiceImg5 from '../Image/img-service-05.jpg';
import ServiceImg6 from '../Image/img-service-06.jpg';
import ServiceImg7 from '../Image/img-service-07.jpg';
import ServiceImg8 from '../Image/img-service-08.jpg';
import ServiceImg9 from '../Image/img-service-09.jpg';
import arrow_360 from '../Image/arrow_360.png';
import Powerwall1 from '../Image/img-powerwall-01.png';
import Powerwall2 from '../Image/img-powerwall-02.png';
import HelpImg from '../Image/img-help-bg.jpg';
import Contact from '../Image/img-title-contact.png';

const SCRIPT_URL = 'https://sdk.form.run/js/v2/embed.js';
const FORMRUN_FORM = '@nemy-56qmmZ1IrN1nz1Po1qL7';

export default function SmartMIrai () {

    useEffect(() => {
        let timeoutId = setTimeout(() => {
            const head = document.getElementsByTagName('head')[0]
            const scriptElement = document.createElement('script')
            scriptElement.src = SCRIPT_URL
        
            head.appendChild(scriptElement)
            return () => {
                head.removeChild(scriptElement)
            }
        }, 2000)
     
        return () => {
            clearTimeout(timeoutId)
        }
    
    }, [])

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        // Target node to observe
        const targetNode = document.getElementById('wait_loading')
    
        // Options for the observer - observe 'childList' mutation and the subtree under the targetNode
        const config = { childList: true, subtree: true }
    
        // Creation of the Mutation Observer
        const observer = new MutationObserver((mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                    for (let node of mutation.addedNodes) {
                        if (node.id === 'iframe' || node.tagName === 'IFRAME') {
                            // Stop the observer
                            observer.disconnect()
                            // Execute necessary code when node is added
                            setLoading(false)
                        }
                    }
                }
            }
        })
    
        // Start observing
        observer.observe(targetNode, config)
    
        // Clean up by disconnecting the observer when the component unmounts
        return () => observer.disconnect()
    }, [])

    const VRClick1 = () => {
        const url = 'https://nemy.co.jp/hvac/2024/VRtour/';
        window.open(url, '_blank')
    }

    return(
        <div className='PageBase'>
            <div className='TopBack'>
                <Link to='/'><img src={title} className='TitleImg' alt=''/></Link>
                <img src={event} className='EventImg' alt=''/>
                <div className='EventTextArea'>
                    <p className='EventText'>HVACブース来場、<br/>ありがとうございます！</p>
                </div>
                <p className='EventVR' onClick={() => {VRClick1()}}>展示会ブースVRtour公開中</p>
            </div>
            <div className='AreaBack PageLabel'>
                <img src={SmartMiraiImg} className='LabelImg' alt=''/>
            </div>
            <div className='AreaBack GroupBack'>
                <p className="MKTitle">
                    <br/><br/><br/><br/>
                </p>
                <div className='AreaBack EnergieBack'>
                    <div className='EnergieAreaL'>
                        <p className='EnergieTitle'>再生可能エネルギーインフラ事業<br/><span className='EnergieTitleS'>「Smart Mirai」</span></p>
                        <div className='BorderB EnergieLine'></div>
                        <p className='EnergieText'>
                            Smartmirai事業では、最先端の技術や知見を集約し、<br/>
                            それぞれのライフスタイルやニーズにあった、<br/>
                            アプローチで住宅設備に新たな景色を創造します。<br/>
                            イノベーションとサスティナビリティを取り入れた事業を推進し、<br/>
                            グローバル企業の一員として、世界に貢献していきます。
                        </p>
                    </div>
                    <div className='EnergieAreaR'>
                        <img src={AboutImg} className='EnergieImg' alt=''/>
                    </div>
                </div>
            </div>
            <div className='AreaBack'>
                <div className='AboutImgArea'>
                    <img src={AboutImg1} className='AboutImg' alt=''/>
                    <img src={AboutImg2} className='AboutImg' alt=''/>
                </div>
                <div className='ServiceTitle'>SERVICE</div>
                <div className='ServiceTitleS'>サービス内容</div>
                <div className='BorderW MKline'></div>
                
                <div className='MKZeroAreaY'>
                    <div className='ServiceAreaX'>
                        <img src={ServiceImg1} className='ServiceImg' alt=''/>
                        <p className='MKZeroTitle'>家庭用蓄電池Powerwall</p>
                        <p className='ServiceText'>
                            当社は、米：家庭用テスラの蓄電池<br/>
                            Powerwallの認定施こう工会社に認定されました。
                        </p>
                        <img src={arrow_360} className='ArrowY' alt=''/>
                    </div>
                    <div className='ServiceAreaX'>
                        <img src={ServiceImg2} className='ServiceImg' alt=''/>
                        <p className='MKZeroTitle'>陸屋根架台/PV-DyMo</p>
                        <p className='ServiceText'>
                            太陽光発電システムの機能をフルに活用して、<br/>
                            エネルギーを効率よく得る架台
                        </p>
                        <img src={arrow_360} className='ArrowY' alt=''/>
                    </div>
                    <div className='ServiceAreaX'>
                        <img src={ServiceImg3} className='ServiceImg' alt=''/>
                        <p className='MKZeroTitle'>営農型発電システム</p>
                        <p className='ServiceText'>
                            営農型発電システムは、太陽光を農業生産と発電<br/>
                            とで共有する取り組みです。
                        </p>
                        <img src={arrow_360} className='ArrowY' alt=''/>
                    </div>
                </div>
                <div className='MKZeroAreaY'>
                    <div className='ServiceAreaX'>
                        <img src={ServiceImg4} className='ServiceImg' alt=''/>
                        <p className='MKZeroTitle'>自家消費型発電システム</p>
                        <p className='ServiceText'>
                            自身で発電し、それを使う「電気の自給自足」<br/>
                            システムのことです。
                        </p>
                        <img src={arrow_360} className='ArrowY' alt=''/>
                    </div>
                    <div className='ServiceAreaX'>
                        <img src={ServiceImg5} className='ServiceImg' alt=''/>
                        <p className='MKZeroTitle'>産業用太陽光発電太陽光発電システム</p>
                        <p className='ServiceText'>
                            再生可能エネルギーの活用で、温暖化対策の推進<br/>
                            温室効果ガスの排出量削減が見込めます。
                        </p>
                        <img src={arrow_360} className='ArrowY' alt=''/>
                    </div>
                    <div className='ServiceAreaX'>
                        <img src={ServiceImg6} className='ServiceImg' alt=''/>
                        <p className='MKZeroTitle'>スマートホーム</p>
                        <p className='ServiceText'>
                            AI時代の暮らしを支える「AloT」でスマートホーム<br/>
                            にイノベーションを起こす事業を展開しています。<br/>
                            最新の技術で未来へと...
                        </p>
                        <img src={arrow_360} className='ArrowY Ypuls' alt=''/>
                    </div>
                </div>
                <div className='MKZeroAreaY'>
                    <div className='ServiceAreaX'>
                        <img src={ServiceImg7} className='ServiceImg' alt=''/>
                        <p className='MKZeroTitle'>
                            蓄電池つき住宅用太陽光発電<br/>
                            システム/ソーラーモンスターQ
                        </p>
                        <p className='ServiceText'>
                            ご自宅で電気をためつつ発電し家計を助けます。<br/>
                            その太陽光発電についてご案内。
                        </p>
                        <img src={arrow_360} className='ArrowY YpulsT' alt=''/>
                    </div>
                    <div className='ServiceAreaX'>
                        <img src={ServiceImg8} className='ServiceImg' alt=''/>
                        <p className='MKZeroTitle'>
                            蓄電池つき住宅用太陽光発電<br/>
                            システム/ソーラーモンスターQ
                        </p>
                        <p className='ServiceText'>
                            ご自宅で電気をためつつ発電し家計を助けます。<br/>
                            その蓄電池についてご案内。
                        </p>
                        <img src={arrow_360} className='ArrowY YpulsT' alt=''/>
                    </div>
                    <div className='ServiceAreaX'>
                        <img src={ServiceImg9} className='ServiceImg' alt=''/>
                        <p className='MKZeroTitle'>メンテナンス</p>
                        <p className='ServiceText'>
                            発電事業主様の緊急SOSを365日24時間受け付<br/>
                            けるPVレンジャーです。
                        </p>
                        <img src={arrow_360} className='ArrowY' alt=''/>
                    </div>
                </div>
                <div className='PageSpace'></div>
            </div>
            <div className='AreaBack GroupBack'>
                <div className='PageSpace'></div>
                <div className='PowerwallTitle'>家庭用蓄電池<br/><span className='PowerwallTitleS'>Powerwall</span>とは？</div>
                <div className='BorderB MKline'></div>
                <div className='PageSpace'></div>
                <div className='PowerwallArea'>
                    <div className='PowerwallAreaText'>
                        <p className='PowerwallText1'>
                            家庭用蓄電池Powerwallは<br/>
                            環境に優しく災害時なども電気が使える<br/>
                            強い味方になります
                        </p>
                        <p className='PowerwallText2'>
                            Powerwallとは、太陽光発電システムと一緒に利用することで、太陽光発電システム<br/>
                            で作られた電気を自宅で貯めることができる家庭用蓄電池です。太陽光発電は環境に<br/>
                            も優しく、昼間に貯めたクリーンな電力を夜間も使うことができお得に電気を使うこ<br/>
                            とができます。<br/>
                            <br/>
                            また地震や台風等の災害により停電が発生した時には、非常用電源としても機能する<br/>
                            のが、安心ポイント。
                        </p>
                    </div>
                    <div className='PowerwallAreaImg'>
                        <img src={Powerwall1} className='PowerwallImg' alt=''/>
                    </div>
                </div>
                <div className='PowerwallArea'>
                    <div className='PowerwallAreaImg'>
                        <img src={Powerwall2} className='PowerwallImg' alt=''/>
                    </div>
                    <div className='PowerwallAreaText'>
                        <p className='PowerwallText1'>
                            モニタリングと最適運転が可能
                            <h6><span className='PowerwallText1S'>01</span>節約を最大化する運転モードを搭載<br/>
                            <span className='PowerwallText1S'>02</span>常に接続して状況を監視し停電や悪天候に備える<br/>
                            <span className='PowerwallText1S'>03</span>ご自宅の電力状況を確認・管理</h6>
                        </p>
                        <p className='PowerwallText2'>
                            Tesla アプリでは、ご自宅における太陽光発電量や電気の消費量などをリア<br/>
                            ルタイムでモニタリングできます。ご自宅のエネルギー自給率を高めたり、<br/>
                            万が一の停電に備えたり、節約を最大化したりと、ご希望の目的に合わせて<br/>
                            Powerwallの設定を調整することができます。<br/>
                            <br/>
                            また、通知機能や遠隔アクセスにより、いつでもどこからでもご自身の<br/>
                            Powerwallをコントロールしたり稼働状況を確認することができます。
                        </p>
                    </div>
                </div>
                <div className='PageSpace'></div>
            </div>
            <div className='AreaBack'>
                <img src={HelpImg} className='HelpImg' alt=''/>
                <div className='HelpImgArea'>
                    <p className='HelpImgAreaTitle'>こんなお困りごとはございませんか？</p>
                    <p className='HelpImgAreaText'>
                        <br/>
                        地震や台風や豪雨など、生活に大きな支障が出る災害が多い日本。<br/>
                        その際に多くの人が心配するのが「電気」ではないでしょうか。<br/>
                        <br/>
                        テスラ家庭用蓄電池Powerwallであれば、一般的な住宅の電力を一日まかなうことが可能。<br/>
                        停電が長期化した場合でも電気を使うことができるので、慌てて避難所に行く必要はなくな<br/>
                        ります。Powerwallで、もしもの時の「安心」を確保してみませんか？
                        <br/><br/><br/><br/>
                    </p>
                    <Link to='https://nemytesla.com/'>
                        <div className='HelpImgAreaBtn'>BCP対策についてはこちら</div>
                    </Link>
                </div>
            </div>
            <div className='AreaBack GroupBack'>
                <img src={Contact} className='GROUPLogo' alt=''/>
            </div>
            <div>
                {loading ? <div className="wait_loading_message">
                    <div className="loading">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <p>フォームの準備中です。しばらくお待ちください。</p>
                </div> : ''}
                <div id="wait_loading">
                    <div className="formrun-embed" data-formrun-form={FORMRUN_FORM} data-formrun-redirect="true" />
                </div>
            </div>
            <div className='AreaBottom'>
                <p className='FooterText'>Copyrigh © ネミー株式会社| IRサイト All Rights Reseved.</p>
            </div>
        </div>
    )
}