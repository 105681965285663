import '../CSS/Style.css';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import title from '../Image/title.png';
import event from '../Image/event.png';
import MatchKobaImg from '../Image/MatchKoba.png';
import Icon from '../Image/Icon.png';
import CostCut from '../Image/CostCut.png';
import CostCutText from '../Image/CostCutText.png';
import ZeroImg1 from '../Image/ZeroImg1.png';
import ZeroImg2 from '../Image/ZeroImg2.png';
import ZeroImg3 from '../Image/ZeroImg3.png';
import ZeroImg4 from '../Image/ZeroImg4.png';
import ZeroImg5 from '../Image/ZeroImg5.png';
import ZeroImg6 from '../Image/ZeroImg6.png';

const SCRIPT_URL = 'https://sdk.form.run/js/v2/embed.js';
const FORMRUN_FORM = '@nemy-56qmmZ1IrN1nz1Po1qL7';

export default function MatchKoba () {

    useEffect(() => {
        let timeoutId = setTimeout(() => {
            const head = document.getElementsByTagName('head')[0]
            const scriptElement = document.createElement('script')
            scriptElement.src = SCRIPT_URL
        
            head.appendChild(scriptElement)
            return () => {
                head.removeChild(scriptElement)
            }
        }, 2000)
     
        return () => {
            clearTimeout(timeoutId)
        }
    
    }, [])

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        // Target node to observe
        const targetNode = document.getElementById('wait_loading')
    
        // Options for the observer - observe 'childList' mutation and the subtree under the targetNode
        const config = { childList: true, subtree: true }
    
        // Creation of the Mutation Observer
        const observer = new MutationObserver((mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                    for (let node of mutation.addedNodes) {
                        if (node.id === 'iframe' || node.tagName === 'IFRAME') {
                            // Stop the observer
                            observer.disconnect()
                            // Execute necessary code when node is added
                            setLoading(false)
                        }
                    }
                }
            }
        })
    
        // Start observing
        observer.observe(targetNode, config)
    
        // Clean up by disconnecting the observer when the component unmounts
        return () => observer.disconnect()
    }, [])

    const VRClick1 = () => {
        const url = 'https://nemy.co.jp/hvac/2024/VRtour/';
        window.open(url, '_blank')
    }

    return(
        <div className='PageBase'>
            <div className='TopBack'>
                <Link to='/'><img src={title} className='TitleImg' alt=''/></Link>
                <img src={event} className='EventImg' alt=''/>
                <div className='EventTextArea'>
                    <p className='EventText'>HVACブース来場、<br/>ありがとうございます！</p>
                </div>
                <p className='EventVR' onClick={() => {VRClick1()}}>展示会ブースVRtour公開中</p>
            </div>
            <div className='AreaBack PageLabel'>
                <img src={MatchKobaImg} className='LabelImg' alt=''/>
            </div>
            <div className='AreaBack GroupBack'>
                <p className="MKTitle">
                    <br/><br/><br/><br/>
                    ネミーのMATCH KOBAで<br/>
                    あなたの問題解決しませんか？
                </p>
                <div className='BorderB MKline'></div>
                <div className='CustomerArea'>
                    <div className='CustomerT'>
                        <img src={Icon} className='CustomerIcon' alt=''/>
                        <div className='CustomerTextAreaOb'></div>
                        <div className='CustomerTextArea'>
                            <p className='CustomerText'>
                                <br/><br/><br/>
                                現行の協力会社から、値上げ要請が来て、<br/>
                                原価が大きく上がってしまう...
                            </p>
                        </div>
                    </div>
                    <div className='CustomerT'>
                        <img src={Icon} className='CustomerIcon' alt=''/>
                        <div className='CustomerTextAreaOb'></div>
                        <div className='CustomerTextArea'>
                            <p className='CustomerText'>
                                <br/><br/><br/>
                                既存の協力会社の生産拠点が遠方にあり、<br/>
                                納期がどうしても伸びてしまう...
                            </p>
                        </div>
                    </div>
                </div>
                <div className='CustomerArea'>
                    <div className='CustomerT'>
                        <img src={Icon} className='CustomerIcon' alt=''/>
                        <div className='CustomerTextAreaOb'></div>
                        <div className='CustomerTextArea CABottom'>
                            <p className='CustomerText'>
                                <br/><br/>
                                依頼時はメールで一つ一つ図面を添付して<br/>
                                依頼するため、<br/>
                                見積依頼の時点で工数がかなり掛かってし<br/>
                                まう...
                            </p>
                        </div>
                    </div>
                    <div className='CustomerT'>
                        <img src={Icon} className='CustomerIcon' alt=''/>
                        <div className='CustomerTextAreaOb'></div>
                        <div className='CustomerTextArea CABottom'>
                            <p className='CustomerText'>
                                <br/><br/><br/>
                                既存業者の事業継承問題により、今後の<br/>
                                部品の供給に大きな不安がある...
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='AreaBack'>
                <div className='PageSpace'></div>
                <div className='MKCostArea'>
                    <div className='MKCostTopText'>ネミーのMATCH KOBAなら！</div>
                </div>
                <div className='MKCostAreaT'></div>
                <div className='MKCostText1'>見積もりから業者の選定、面倒な行程管理まで</div>
                <div className='MKCostText2'>「ものづくり」に関する<br/>　あらゆるコストをカットできます。</div>
                <div className='BorderY MKline'></div>
                <img src={CostCut} className='CostCutImg' alt=''/>
                <img src={CostCutText} className='CostCutImg' alt=''/>
                <div className='PageSpace'></div>
            </div>
            <div className='AreaBack GroupBack'>
                <div className='PageSpace'></div>
                <div className='MKCostText2 TextBlack'>製造管理行程、工数をゼロにする<br/>MATCH KOBAです。</div>
                <div className='BorderB MKline'></div>
                <div className='MKZeroAreaY'>
                    <div className='MKZeroAreaX'>
                        <p className='MKZero'>ZERO1</p>
                        <img src={ZeroImg1} className='MKZeroImg' alt=''/>
                        <p className='MKZeroTitle'>見積もり、工数が<br/>ゼロになる！</p>
                        <p className='MKZeroText'>自動見積もり機能で見積もりの手間がゼロになります！</p>
                    </div>
                    <div className='MKZeroAreaX'>
                        <p className='MKZero'>ZERO2</p>
                        <img src={ZeroImg2} className='MKZeroImg' alt=''/>
                        <p className='MKZeroTitle'>製造業者を探す工数が<br/>ゼロになる！</p>
                        <p className='MKZeroText'>自動見積もりをもとに最適な製造業者を選定いたします。</p>
                    </div>
                    <div className='MKZeroAreaX'>
                        <p className='MKZero'>ZERO3</p>
                        <img src={ZeroImg3} className='MKZeroImg' alt=''/>
                        <p className='MKZeroTitle'>発注後の工程管理が<br/>ゼロになる！</p>
                        <p className='MKZeroText'>製造業者を選定した後、発注から納品までの管理もMATHC KOBAが全て行います。</p>
                    </div>
                </div>
                <div className='MKZeroAreaY'>
                    <div className='MKZeroAreaX'>
                        <p className='MKZero'>ZERO4</p>
                        <img src={ZeroImg4} className='MKZeroImg' alt=''/>
                        <p className='MKZeroTitle'>製造業者とのコミュニケーション<br/>コストがゼロになる！</p>
                        <p className='MKZeroText'>製造業者の管理はMATCH KOBAにお任せください。</p>
                    </div>
                    <div className='MKZeroAreaX'>
                        <p className='MKZero'>ZERO5</p>
                        <img src={ZeroImg5} className='MKZeroImg' alt=''/>
                        <p className='MKZeroTitle'>納品後は安心の保証で<br/>品質管理コストがゼロになる！</p>
                        <p className='MKZeroText'>納品後の品質保証もMATCH KOBAにお任せください。</p>
                    </div>
                    <div className='MKZeroAreaX'>
                        <p className='MKZero'>ZERO6</p>
                        <img src={ZeroImg6} className='MKZeroImg' alt=''/>
                        <p className='MKZeroTitle'>ミスコミュみケーションが<br/>ゼロになる！</p>
                        <p className='MKZeroText'>ものづくりのプロが運営管理しているため、ミスコミュみケーションがゼロになります。</p>
                    </div>
                </div>
                <div className='PageSpace'></div>
            </div>
            <div className='AreaBack FAQBack'>
                <div className='FAQAreaL'>
                    <p className="FAQTitle"><br/>FAQ</p>
                    <p className='FAQTitleSet'>よくあるご質問</p>
                    <div className='BorderB FAQline'></div>
                </div>
                <div className='FAQAreaR CABottom'>
                    <div className='FAQBanner'>
                        <p className="QuestionText"><br/><span className="Qtext">Q1.</span>どのような会社と取引していますか？</p>
                        <p className="AnswerText">飯田グループ、イナバ物置のような大手から中小企業まで多くの企業と取引しております。</p>
                    </div>
                    <div className='FAQBanner'>
                        <p className="QuestionText"><br/><span className="Qtext">Q2.</span>施工に関する資格は持っていますか？</p>
                        <p className="AnswerText">電気工事2級、とび・土工工事業があります。</p>
                    </div>
                    <div className='FAQBanner'>
                        <p className="QuestionText"><br/><span className="Qtext">Q3.</span>事業展開はどの地域が中心ですか？</p>
                        <p className="AnswerText">全国対応可能ですが、主に関東が中心です。</p>
                    </div>
                    <div className='FAQBanner'>
                        <p className="QuestionText"><br/><span className="Qtext">Q4.</span>相見積りの際に、品質にバラつきはないのか？</p>
                        <p className="AnswerText">お取引先として長年東芝や日立とのお付き合いをさせていただいているので、ご安心ください。</p>
                    </div>
                </div>
            </div>
            <div>
                {loading ? <div className="wait_loading_message">
                    <div className="loading">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <p>フォームの準備中です。しばらくお待ちください。</p>
                </div> : ''}
                <div id="wait_loading">
                    <div className="formrun-embed" data-formrun-form={FORMRUN_FORM} data-formrun-redirect="true" />
                </div>
            </div>
            <div className='AreaBottom'>
                <p className='FooterText'>Copyrigh © ネミー株式会社| IRサイト All Rights Reseved.</p>
            </div>
        </div>
    )
}