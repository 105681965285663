import '../CSS/Style.css';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import title from '../Image/title.png';
import event from '../Image/event.png';
import AntiSnowTopImg from '../Image/AntiSnowTop.jpg';
import AntiSnow1 from '../Image/AntiSnow1.jpg';
import AntiSnow2 from '../Image/AntiSnow2.jpg';
import AntiSnow3 from '../Image/AntiSnow3.jpg';

const SCRIPT_URL = 'https://sdk.form.run/js/v2/embed.js';
const FORMRUN_FORM = '@nemy-56qmmZ1IrN1nz1Po1qL7';

export default function AntiSnow () {

    useEffect(() => {
        let timeoutId = setTimeout(() => {
            const head = document.getElementsByTagName('head')[0]
            const scriptElement = document.createElement('script')
            scriptElement.src = SCRIPT_URL
        
            head.appendChild(scriptElement)
            return () => {
                head.removeChild(scriptElement)
            }
        }, 2000)
     
        return () => {
            clearTimeout(timeoutId)
        }
    
    }, [])

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        // Target node to observe
        const targetNode = document.getElementById('wait_loading')
    
        // Options for the observer - observe 'childList' mutation and the subtree under the targetNode
        const config = { childList: true, subtree: true }
    
        // Creation of the Mutation Observer
        const observer = new MutationObserver((mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                    for (let node of mutation.addedNodes) {
                        if (node.id === 'iframe' || node.tagName === 'IFRAME') {
                            // Stop the observer
                            observer.disconnect()
                            // Execute necessary code when node is added
                            setLoading(false)
                        }
                    }
                }
            }
        })
    
        // Start observing
        observer.observe(targetNode, config)
    
        // Clean up by disconnecting the observer when the component unmounts
        return () => observer.disconnect()
    }, [])

    const VRClick1 = () => {
        const url = 'https://nemy.co.jp/hvac/2024/VRtour/';
        window.open(url, '_blank')
    }

    return(
        <div className='PageBase'>
            <div className='TopBack'>
                <Link to='/'><img src={title} className='TitleImg' alt=''/></Link>
                <img src={event} className='EventImg' alt=''/>
                <div className='EventTextArea'>
                    <p className='EventText'>HVACブース来場、<br/>ありがとうございます！</p>
                </div>
                <p className='EventVR' onClick={() => {VRClick1()}}>展示会ブースVRtour公開中</p>
            </div>
            <div className='AreaBack LineUpLabel'>
                <div className='LineUpTopArea'>
                    <p className='LineUpTopTitle1'>　防雪設備</p>
                    <p className='LineUpTopTitle2'>
                        1歩先の省エネ対策！<br/>
                        空調機の運転効率UP！
                    </p>
                </div>
                <img src={AntiSnowTopImg} className='LineUpTopImg' alt=''/>
            </div>
            <div className='AreaBack GroupBack'>
                <div className='PageSpace'></div>
                <p className='ShanetteTitle'>防雪設備とは？</p>
                <div className='BorderB MKline'></div>
                <div className='ShanetteY'>
                    <div className='ShanetteX'>
                        <img src={AntiSnow1} className='ShanetteImg' alt=''/>
                    </div>
                    <div className='ShanetteX'>
                        <p className='ShanetteQTitle'>PRODUCT1<br/>防雪フード</p>
                        <p className='ShanetteQTextB'>防雪アイテムの代表 /<br/>風向ガイドとしても使用OK /<br/>指定色塗装できます</p>
                        <p className='ShanetteQTextS'>
                            ・吸込用フード取付により、雪による目詰まりを防止<br/>
                            ・デフロスト（霜取り）運転の頻度を減らし、省エネ運転をサポート<br/>
                            ・設置環境に応じてセレクト可能<br/>
                            ・側面吹出、高さ変更も可能<br/>
                            ・軒下など狭少スペースにも対応
                        </p>
                    </div>
                </div>
                <div className='ShanetteY'>
                    <div className='ShanetteX'>
                        <p className='ShanetteQTitle SQTST'>PRODUCT2</p>
                        <p className='ShanetteQTextB SQTST'>防雪ネット</p>
                        <p className='ShanetteQTextS SQTST'>
                            ・風の影響を受けにくい<br/>
                            ・重く湿った雪に最適<br/>
                            ・ステンレス製での製作可能<br/>
                            ・高さ変更可能<br/>
                            ・傾斜付きなど特注対応可能
                        </p>
                    </div>
                    <div className='ShanetteX'>
                        <img src={AntiSnow2} className='ShanetteImg' alt=''/>
                    </div>
                </div>
                <div className='ShanetteY'>
                    <div className='ShanetteX'>
                        <img src={AntiSnow3} className='ShanetteImg' alt=''/>
                    </div>
                    <div className='ShanetteX'>
                        <p className='ShanetteQTitle'>PRODUCT3</p>
                        <p className='ShanetteQTextB'>防雪ルーフ</p>
                        <p className='ShanetteQTextS'>
                            ・氷柱ができにくい<br/>
                            ・積雪に強い<br/>
                            ・雪質を選ばない<br/>
                            ・風の影響を受けにくい<br/>
                            ・開口面積が大きく、機器への負担が少ない
                        </p>
                    </div>
                </div>
                <div className='PageSpace'></div>
            </div>
            <div className='AreaBack FAQBack'>
                <div className='FAQAreaL'>
                    <p className="FAQTitle"><br/>FAQ</p>
                    <p className='FAQTitleSet'>よくあるご質問</p>
                    <div className='BorderB FAQline'></div>
                </div>
                <div className='FAQAreaR CABottom'>
                    <div className='FAQBanner'>
                        <p className="QuestionText"><br/><span className="Qtext">Q1.</span>上吹き出しの室外機で防雪フードや防雪ルーフを逆向きに設置したい</p>
                        <p className="AnswerText">一部の製品では反転取付ができます。<br/>可否は仕様書の記載をご確認ください。</p>
                    </div>
                    <div className='FAQBanner'>
                        <p className="QuestionText"><br/><span className="Qtext">Q2.</span>吹出用フードの背面穴は塞いでも良いですか？</p>
                        <p className="AnswerText">背面穴はフード正面からの吹込みを逃すためのものです。<br/>塞ぐとファンの破損やフードの脱落の危険があるため、塞がないようお気をつけください。</p>
                    </div>
                    <div className='FAQBanner'>
                        <p className="QuestionText"><br/><span className="Qtext">Q3.</span>防雪フード、防雪ネットの付属の組み立てネジについて知りたい</p>
                        <p className="AnswerText">SUS410（ジオメット＋ジンロイ処理）のエスタイトネジが付属されています。</p>
                    </div>
                    <div className='FAQBanner'>
                        <p className="QuestionText"><br/><span className="Qtext">Q4.</span>計算書はもらえますか？</p>
                        <p className="AnswerText">各種計算書をご用意しています。<br/>耐震計算書/耐風計算書/積雪荷重計算書</p>
                    </div>
                </div>
            </div>
            <div>
                {loading ? <div className="wait_loading_message">
                    <div className="loading">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <p>フォームの準備中です。しばらくお待ちください。</p>
                </div> : ''}
                <div id="wait_loading">
                    <div className="formrun-embed" data-formrun-form={FORMRUN_FORM} data-formrun-redirect="true" />
                </div>
            </div>
            <div className='AreaBottom'>
                <p className='FooterText'>Copyrigh © ネミー株式会社| IRサイト All Rights Reseved.</p>
            </div>
        </div>
    )
}