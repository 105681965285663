import '../CSS/Style.css';
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

import title from '../Image/title.png';
import event from '../Image/event.png';
import Link1 from '../Image/Link1.png';
import Link2 from '../Image/Link2.png';
import Link3 from '../Image/Link3.png';
import Link4 from '../Image/Link4.png';
import Arrow from '../Image/Arrow.png';
import Human from '../Image/Human.png';
import GroUp from '../Image/GroUp.png';
import GROUPLogo from '../Image/GROUPLogo.png';
import Pluginlogo from '../Image/Pluginlogo.png';
import NKELogo from '../Image/NKE.png';

export default function Top () {

    const location = useLocation();
    useEffect(() => {
        // Google Analytics 測定 ID を入力して設定
        ReactGA.initialize("G-73HTNT8XZ8");
        ReactGA.send({
            hitType: "pageview",
            // アクセスしたパス (pathname) とクエリ文字列 (search) を送付する (必要に応じて編集する)
            page: location.pathname + location.search,
        });
    }, [location]);

    // const nav = useNavigate();
    const VRClick1 = () => {
        const url = 'https://nemy.co.jp/hvac/2024/VRtour/';
        window.open(url, '_blank')
    }

    return(
        <div className='PageBase'>
            <div className='TopBack'>
                <img src={title} className='TitleImg' alt=''/>
                <img src={event} className='EventImg' alt=''/>
                <div className='EventTextArea'>
                    <p className='EventText'>HVACブース来場、<br/>ありがとうございます！</p>
                </div>
                <p className='EventVR' onClick={() => {VRClick1()}}>展示会ブースVRtour公開中</p>
            </div>
            <div className='AreaBack'>
                <div className='LinkImgArea'>
                    <Link to='/MatchKoba'>
                        <img src={Link1} className='LinkImg' alt=''/>
                    </Link>
                    <Link to='/Construction'>
                        <img src={Link2} className='LinkImg' alt=''/>
                    </Link>
                </div>
                <div className='LinkImgArea'>
                    <Link to='/SmartMIrai'>
                        <img src={Link3} className='LinkImg' alt=''/>
                    </Link>
                    <Link to='https://nemytesla.com/'>
                        <img src={Link4} className='LinkImg' alt=''/>
                    </Link>
                </div>
                <img src={Arrow} className='ArrowImg' alt=''/>
            </div>
            <div className='AreaBack TextArea'>
                <div className='TextAreaL'>
                    <p className='TextABOUT'>ABOUT</p>
                    <p className='TextNemy'>ネミー株式会社とは？</p>
                    <div className='BorderY ABOUTline'></div>
                    <p className='TitleNemy'>
                        デジタルトランスフォーメーション時代<br/>
                        における生活環境の価値創造メーカーへ
                    </p>
                    <p className='TextNemy'>
                        ネミーは1967年、空調・各種機器の架台メーカーとして出発しました。<br/>
                        従来に無い新しい価値を付加することで、<br/>
                        「機器の進化にお応えできる」架台メーカーから、<br/>
                        この目線の変更により「快適空間の創造」という新たなビジョンを描くに至ります。<br/>
                        探求を続けた結果、着目したのが太陽光エネルギーでした。<br/>
                        既存のモジュールメーカーやシステムにとらわれない、<br/>
                        これからも、デジタルトランスフォーメーション時代における<br/>
                        生活環境の価値創造メーカーと名乗るに<br/>
                        ふさわしい企業に成長するための努力を続けてまいります。
                    </p>
                </div>
                <div className='TextAreaR'>
                    <img src={Human} className='HumanImg' alt=''/>
                </div>
            </div>
            <img src={GroUp} className='GroUpImg' alt=''/>
            <div className='AreaBack GroupBack'>
                <div className='LogoArea'>
                    <img src={GROUPLogo} className='GROUPLogo' alt=''/>
                </div>
                <div className='LogoArea'>
                    <Link to='https://nemy.co.jp/plugin/'>
                        <img src={Pluginlogo} className='Pluginlogo' alt=''/>
                    </Link>
                    <Link to='http://japan-k.jp/'>
                        <img src={NKELogo} className='NKELogo' alt=''/>
                    </Link>
                </div>
            </div>
            <div className='AreaBottom'>
                <p className='FooterText'>Copyrigh © ネミー株式会社| IRサイト All Rights Reseved.</p>
            </div>
        </div>
    )
}