import '../CSS/Style.css';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import title from '../Image/title.png';
import event from '../Image/event.png';
import ConstructionImg from '../Image/Construction.png';
import LineUpImg1 from '../Image/LineUpImg1.png';
import LineUpImg2 from '../Image/LineUpImg2.png';
import LineUpImg3 from '../Image/LineUpImg3.png';
import LineUpImg4 from '../Image/LineUpImg4.png';
import ABOUTImg from '../Image/ABOUTImg.png';

const SCRIPT_URL = 'https://sdk.form.run/js/v2/embed.js';
const FORMRUN_FORM = '@nemy-56qmmZ1IrN1nz1Po1qL7';

export default function Construction () {

    useEffect(() => {
        let timeoutId = setTimeout(() => {
            const head = document.getElementsByTagName('head')[0]
            const scriptElement = document.createElement('script')
            scriptElement.src = SCRIPT_URL
        
            head.appendChild(scriptElement)
            return () => {
                head.removeChild(scriptElement)
            }
        }, 2000)
     
        return () => {
            clearTimeout(timeoutId)
        }
    
    }, [])

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        // Target node to observe
        const targetNode = document.getElementById('wait_loading')
    
        // Options for the observer - observe 'childList' mutation and the subtree under the targetNode
        const config = { childList: true, subtree: true }
    
        // Creation of the Mutation Observer
        const observer = new MutationObserver((mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                    for (let node of mutation.addedNodes) {
                        if (node.id === 'iframe' || node.tagName === 'IFRAME') {
                            // Stop the observer
                            observer.disconnect()
                            // Execute necessary code when node is added
                            setLoading(false)
                        }
                    }
                }
            }
        })
    
        // Start observing
        observer.observe(targetNode, config)
    
        // Clean up by disconnecting the observer when the component unmounts
        return () => observer.disconnect()
    }, [])

    const VRClick1 = () => {
        const url = 'https://nemy.co.jp/hvac/2024/VRtour/';
        window.open(url, '_blank')
    }

    return(
        <div className='PageBase'>
            <div className='TopBack'>
                <Link to='/'><img src={title} className='TitleImg' alt=''/></Link>
                <img src={event} className='EventImg' alt=''/>
                <div className='EventTextArea'>
                    <p className='EventText'>HVACブース来場、<br/>ありがとうございます！</p>
                </div>
                <p className='EventVR' onClick={() => {VRClick1()}}>展示会ブースVRtour公開中</p>
            </div>
            <div className='AreaBack PageLabel'>
                <img src={ConstructionImg} className='LabelImg' alt=''/>
            </div>
            <div className='AreaBack GroupBack'>
                <p className="LUTitle1"><br/>LINE UP</p>
                <p className='LUTitle2'>ラインナップ</p>
                <div className='BorderB MKline'></div>
                <div className='PageSpace'></div>
                <div className='LineUpArea'>
                    <div className='CustomerT'>
                        <Link to='/Shanette'><img src={LineUpImg1} className='LineUpImg' alt=''/></Link>
                    </div>
                    <div className='CustomerT'>
                        <Link to='/SoundProof'><img src={LineUpImg2} className='LineUpImg' alt=''/></Link>
                    </div>
                </div>
                <div className='LineUpArea'>
                    <div className='CustomerT'>
                    <Link to='/SnowRoof'><img src={LineUpImg3} className='LineUpImg' alt=''/></Link>
                    </div>
                    <div className='CustomerT'>
                    <Link to='/AntiSnow'><img src={LineUpImg4} className='LineUpImg' alt=''/></Link>
                    </div>
                </div>
            </div>
            <div className='AreaBack TextArea'>
                <div className='PageSpace'></div>
                <div className='TextAreaL'>
                    <p className='TextABOUT'>ABOUT</p>
                    <p className='TextNemy'>ネミー株式会社の建設設備について</p>
                    <div className='BorderY ABOUTline'></div>
                    <p className='TextNemy'>
                        <br/><br/>
                        確固たる技術基盤とゆるぎないノウハウを基に、<br/>
                        より良い製品とより良い未来を創る<br/>
                        確固たる技術基盤とゆるぎないノウハウを基に日本の未来を照らす<br/>
                        建築設備機材事業。オープンイノベーションと先端技術活用推進と<br/>
                        いった次世代に未来を臨むプロジェクトにトライし、<br/>
                        より良い製品とより良い未来を創ることに努めています。
                    </p>
                </div>
                <div className='TextAreaR'>
                    <img src={ABOUTImg} className='HumanImg' alt=''/>
                </div>
                <div className='PageSpace'></div>
            </div>

            <div className='AreaBack FAQBack'>
                <div className='FAQAreaL'>
                    <p className="FAQTitle"><br/>FAQ</p>
                    <p className='FAQTitleSet'>よくあるご質問</p>
                    <div className='BorderB FAQline'></div>
                </div>
                <div className='FAQAreaR CABottom'>
                    <div className='FAQBanner'>
                        <p className="QuestionText"><br/><span className="Qtext">Q1.</span>どのように取り付けますか？</p>
                        <p className="AnswerText">室外機側面に機器ネジがある場合は機器ネジで固定します。機器ネジが無い場合は付属のネジで固定します。</p>
                    </div>
                    <div className='FAQBanner'>
                        <p className="QuestionText"><br/><span className="Qtext">Q2.</span>壁に傷は付きませんか？</p>
                        <p className="AnswerText">天板の壁と当たる面は緩衝材のスポンジが設定されているのでご安心ください。</p>
                    </div>
                </div>
            </div>
            <div>
                {loading ? <div className="wait_loading_message">
                    <div className="loading">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <p>フォームの準備中です。しばらくお待ちください。</p>
                </div> : ''}
                <div id="wait_loading">
                    <div className="formrun-embed" data-formrun-form={FORMRUN_FORM} data-formrun-redirect="true" />
                </div>
            </div>
            <div className='AreaBottom'>
                <p className='FooterText'>Copyrigh © ネミー株式会社| IRサイト All Rights Reseved.</p>
            </div>
        </div>
    )
}